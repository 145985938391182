






























import router from "@/router";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "PinjamanInfo",
  props: { pinjaman: Object },
  setup(props) {
    const goToRiwayat = () =>
      router.push({
        name: "pinjaman-cicilan",
        params: { id: props.pinjaman?.id },
      });
    return {
      // Data
      formatRupiah,

      // Method
      goToRiwayat,
    };
  },
});
